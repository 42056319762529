import axios from "axios";
import AuthHelpers from "@/helpers/AuthHelpers";
import ServiceConstants from "@/constants/serviceConstants";
import store from "@/store/store.js";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "https://walmart-v8-api.pointr.cloud/api/v8"
});

async function requestInterceptor(config) {
  // login service uses another axios object so that this request interceptor is not applied
  await AuthHelpers.refreshToken();
  // Add auth token and content type to every request's header
  config.headers = {
    ...config.headers,
    [ServiceConstants.CONTENT_TYPE]: ServiceConstants.APPLICATION_JSON,
    [ServiceConstants.AUTHORIZATTION]: ServiceConstants.BEARER + AuthHelpers.getAccessToken()
  };
  return config;
}

axiosInstance.interceptors.request.use(requestInterceptor);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.data) {
      console.log(error.response?.data);
    }
    if (error.response?.status === 401) {
      store.commit("CONTENT/IS_FORM_DIRTY", false);
      AuthHelpers.logout();
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
